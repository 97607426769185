import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteAction } from '@core/action/action';
import { AuthenticationService } from '@core/auth/authentication.service';
import { AreaInteresse } from '@core/models/chamada';
import { TipoPessoa } from '@core/models/dominio';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { ChamadaService } from '@core/services/chamada.service';
import { DominioService } from '@core/services/dominio.service';
import { DominioServiceProjeto } from '@core/services/dominioProjeto.service'
import { NotificationService } from '@core/services/notification.service';
import { ProjetosPublicoService } from '@core/services/projetos-publico';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { StatusProjetoEnum } from '@core/models/enum/status-projeto';
import { RelacaoDeSeguidosService } from '@core/services/relacao-de-seguidos.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalJustificativaComponent } from '@modules/chamadas-bloqueadas/components/modal-justificativa/modal-justificativa.component';
import { ProjetoService } from '@core/services/projeto.service';

@Component({
  selector: 'app-list-projetos',
  templateUrl: './list-projetos.component.html',
  styleUrls: ['./list-projetos.component.scss']
})
export class ListProjetosComponent implements OnInit {

  namePage = "Lista de Projetos";

  id!: number;
  acao!: RouteAction;
  criar!: boolean;
  editar!: boolean;
  visualizar!: boolean;

  page = 1;
  pageSize = 5;
  listaProjetos: any[] = []; // criarModel
  tabelaListaProjetos: any[] = [];
  collectionSize: any;

  usuarioLogado: any;
  papelAtivo: any;
  idContaAtiva: any;
  lastPage: number = 0;
  lista: any[] = [];
  usuarioLogadoContas: any[] = [];
  idUsuarioLogado: any;
  message!: string;
  isLoading: boolean = false;
  success: boolean = false;
  exibirErroVista: boolean = false;
  objEnvio: any;
  objEnvioFiltroChamadas: any;
  statusProjetoEnum = StatusProjetoEnum;
  fill: boolean = false;

  listaEmpreendedor: any[] = [];
  listaPatrocinador: any[] = [];
  statusEdital: any[] = [];
  fonteFinanciamento: any[] = [];
  tipoEdital: any[] = [];
  tipoPessoa: any[] = [];
  tipoFoto: any[] = [];
  fotos: any[] = [];
  tipoPessoas: Array<TipoPessoa> = [];
  publicos: any[] = [];
  ods: any[] = [];
  statusProjeto: any[] = [];
  areasDeInteresse: Array<AreaInteresse> = [];
  estados: any[] = [];
  ufs: any;
  municipios: any[] = [];
  tipoDocumento: any[] = [];
  midias: any[] = [];
  areaDeInteresseEdital: any[] = [];
  acaoFiltroProjetoEnum = AcaoFiltrosEnum;
  bloqueadoPeloAdm: boolean = false;
  filtroPaginas: any[]=[];

  teste: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private chamadaService: ChamadaService,
    private dominioService: DominioService,
    private dominioServiceProjeto: DominioServiceProjeto,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private projetosPublicoService: ProjetosPublicoService,
    private relacaoDeSeguidosService: RelacaoDeSeguidosService,
    private translateService: TranslateService,
    private projetoService: ProjetoService
  ) { }

  formFiltroProjetos = this.formBuilder.group({
    areaInteresse: [],
    empreendedor: [],
    estado: [],
    fonteFinanciamento: [],
    municipio: [],
    nomeOuDescricao: [],
    ods: [],
    patrocinador: [],
    statusProjeto: [],
  });

  ngOnInit() {
    this.userlogged();
    this.buscarDadosEtapas();
    this.pesquisar();
  }


  submit():void {
    let objEnvio = { ...this.formFiltroProjetos.value };
    objEnvio.idProjetos = this.idContaAtiva;

    this.chamadaService.listarEditais(objEnvio,this.page,this.pageSize).subscribe((data: any) => {
      this.listaProjetos = data.content;
      this.collectionSize = data.size;
      this.refreshCountries();
    });
  }

  voltarDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  refreshCountries() {
    this.tabelaListaProjetos = this.listaProjetos
      .map((x, i) => ({id: i + 1, ...x}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  reset(){
    this.formFiltroProjetos.reset();

    this.fill == false
  }

  buscarDadosEtapas(): void {
    this.buscarTipoPessoa();
    this.buscarPublicoAlvo();
    this.buscarOds();
    this.buscarAreaInteresse();
    this.buscarEstado();
    this.buscarFonteFinanciamento();
    this.buscarProjetoEmpreendedor();
    this.buscarProjetoPatrocinador();
    this.buscarStatusProjeto();
    this.buscarfiltroPaginas();

  }

  buscarProjetoEmpreendedor(): void{
    this.dominioServiceProjeto.listaEmpreendedor().subscribe((data: any) =>{
      this.listaEmpreendedor = data;
    })
  }

  buscarProjetoPatrocinador(): void{
    this.dominioServiceProjeto.listaPatrocinador().subscribe((data: any) =>{
      this.listaPatrocinador = data;
    })
  }

  buscarTipoPessoa(): void {
    this.dominioService.buscarTipoPessoa().subscribe((data: any) => {
      this.tipoPessoa = data;
      this.tipoPessoa = this.tipoPessoa.filter(x => x.ativo == true);
    });
  }

  buscarEstado(): void {
    this.dominioService.buscarEstado().subscribe((data: any) => {
      this.estados = data;
    });
  }

  buscarMunicipio(idEstado: number): void {
    if(idEstado){
      this.ufs = { "estados": [idEstado] }
      this.dominioService.buscarMunicipioEstados(this.ufs).subscribe((data: any) => {
        this.municipios = data;
      });
    }
    else
      this.municipios = [];
  }

  buscarAreaInteresse(): void {
    this.dominioService.buscarAreaInteresse().subscribe((data: any) => {
      this.areasDeInteresse = data;
      this.areasDeInteresse = this.areasDeInteresse.filter(x => x.ativo == true);
    });
  }

  buscarOds(): void {
    this.dominioService.buscarOdsFiltros().subscribe((data: any) => {
      this.ods = data;
    });
  }

  buscarStatusProjeto(): void {
    this.dominioService.buscarStatusProjeto().subscribe((data: any) => {
      this.statusProjeto = data;
// console.log('nu', this.statusProjeto)
    });
  }

  buscarPublicoAlvo(): void {
    this.dominioService.buscarPublicoAlvo().subscribe((data: any) => {
      this.publicos = data;
      this.publicos = this.publicos.filter(x => x.ativo == true);
    });
  }

  buscarFonteFinanciamento(): void {
    this.dominioService.buscarFonteFinanciamento().subscribe((data: any) => {
      this.fonteFinanciamento = data;
    });
  }

  buscarfiltroPaginas(): void {
    this.dominioService.filtroPaginas().subscribe((data: any) => {
      this.filtroPaginas = data;
    });
  }


  fotosChamada(item: any): any {
    if(item){
      if(item.base64Content && item.base64info){
        let img = item.base64info + item.base64Content;
        if(img){
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }
      }
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  showError(mensagem: any) {
    this.notificationService.showError(mensagem);
  }

  showSuccess(mensagem: any) {
    this.notificationService.showSuccess(mensagem);
  }

  formEnvioFiltroChamadas() {
    this.objEnvioFiltroChamadas = {};
    let objForm = { ...this.formFiltroProjetos.getRawValue() };
    Object.keys(objForm).forEach((key) => {
      if (objForm[key] != undefined && objForm[key] != '')
        this.objEnvio[key] = objForm[key];
    });
  }

  Filtro() {
    if (this.fill == false) {
      this.fill = true
// console.log(this.fill)
    } else if (this.fill == true) {
      this.fill = false
// console.log(this.fill)
    }
    return console.log(this.fill)
  }

  listar(): void {
// console.log(this.formFiltroProjetos.value)
    if (this.formFiltroProjetos.valid) {
      this.page = 1;
      this.pesquisar();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    this.fill == false
  }



  pesquisar(lastPage?: number): void {
    if (lastPage && lastPage == this.page)
      return;

    this.lastPage = this.page;
    let objEnvio = { ...this.formFiltroProjetos.value };
    this.projetosPublicoService.listar(objEnvio, this.page, this.pageSize).subscribe((data: any) => {
      this.lista = data.content;
// console.log(this.lista)
      this.collectionSize = data.totalElements;
    });
  }

  subimit(){
    this.objEnvioFiltroChamadas = {};
    let objForm = { ...this.formFiltroProjetos.getRawValue() };
  }

  userlogged(): void {
    if(this.authenticationService.currentUserValue){
      this.usuarioLogado = this.authenticationService.currentUserValue;
      this.papelAtivo = this.usuarioLogado.papelAtivo;
      this.idContaAtiva = this.usuarioLogado.idContaAtiva;
    }
  }

  acessarLandingPage(url: any): void {
    window.location.href=url;
  }

  trocarSize(): void {
    this.page = 1;
    this.pesquisar(undefined);
  }

  visualizarLandingPage(idProjetos: any){
    window.open(`${window.location.origin}/#/landingpage/projeto/visualizar/${idProjetos}`, '_blank')
  }

  btSeguirProjeto(item: any): void {
    this.relacaoDeSeguidosService.seguirProjeto(item.id).subscribe((data: any) => {
      this.ocultarSeguir(item);

      let mensagem = this.translateService.instant("Seguindo_o_Projeto")
      this.notificationService.showSuccess(mensagem);
    });
  }

  btDeixarDeSeguirProjeto(item: any): void {
    this.relacaoDeSeguidosService.deixarDeSeguirProjeto(item.id).subscribe((data: any) => {
      this.ocultarDeixarDeSeguir(item);

      let mensagem = this.translateService.instant("Deixou_de_seguir_o_Projeto")
      this.notificationService.showSuccess(mensagem);
    });
    location.reload();
  }

  ocultarSeguir(item: any): void {
    item.selectSeguir = !item.selectSeguir;
    item.selectDeixar = item.selectDeixar;
  }

  ocultarDeixarDeSeguir(item: any): void {
    item.selectDeixar = !item.selectDeixar;
    item.selectSeguir = item.selectSeguir;
  }

  bloquearProjeto(dataForm: any){
    if(dataForm){
      this.projetoService.bloquearProjetos(dataForm).subscribe((data: any) => {
        this.notificationService.showSuccess('Projeto bloqueado com sucesso!');
        this.pesquisar();
      })
    }
  }

  openModalBloquear(id: number){
    let modalBloquear = this.modalService.open(ModalJustificativaComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
    modalBloquear.componentInstance.tituloDescricao = AcaoFiltrosEnum.Bloquear;
    modalBloquear.result.then((data) => {
      let objData = data;
      objData = objData?.justificativa;
      if (data) {
        let objEnvio = {
          id: id,
          justificativa: objData
        }
// console.log('objEnvio', objEnvio)
        this.bloquearProjeto(objEnvio);
      }
    })
  }

}
