import { Component, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { RouteAction } from '@core/action/action';
import { ODSService } from '@core/services/ods.service';
import { Subscription, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { TipoFotoEnum } from '@core/models/enum/tipo-foto';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { EmpreendedorPublicoService } from '@core/services/empreendedor-publico';
import { ChamadaPublicoService } from '@core/services/chamada-publico.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalExportPdfComponent } from '@modules/landingpages/components/modal-export-pdf/modal-export-pdf.component';
import { ProdutosContratadosEnum } from '@core/models/enum/produtos-contratados.enum';
import { FotosUsuarioService } from '@core/services/fotos-usuario.service';

@Component({
  selector: 'app-landingpage-empreendedor',
  templateUrl: './landingpage-empreendedor.component.html',
  styleUrls: ['./landingpage-empreendedor.component.scss']
})
export class LandingpageEmpreendedorComponent implements OnInit, OnDestroy {

  cities = [
    {
      id: 0,
      value: "pt-BR",
      avatar: 'https://static.todamateria.com.br/upload/ba/nd/bandeira-do-brasil-og.jpg',
      lingua: 'PT'
    },
    {
      id: 1,
      value: "en-US",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/2560px-Flag_of_the_United_States.svg.png',
      lingua: 'EN'
    },
    {
      id: 2,
      value: "es-ES",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png',
      lingua: 'ES'
    }
  ];
  selectedCity = this.cities[0];
  linguas = 0
  namePage = "Landing Page Empreendedor";

  @Input() idChamada!: number;
  @Input() dadosLandingPage: any;

  subscription!: Subscription;
  dateNow = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  timeDifference!: number;
  secondsToDday!: number;
  minutesToDday!: number;
  hoursToDday!: number;
  daysToDday!: number;

  idAcao!: any;
  acao!: any;
  routeAcao!: RouteAction;
  papelAtivo: any;
  papelContaEnum = PapelContaEnum;
  public isMenuCollapsed = true;
  public isLoggedIn$ = new Observable<boolean>();

  empreendedor: any;
  imagemPerfil: string | any;
  imagemPerfilConta!: string | any;
  imagemCapaBanner: string | any;
  dataLimiteInscricao: any;
  exibirContador: boolean = false;
  produtosContratados = ProdutosContratadosEnum;

  areaInteresse: any[] = [];
  publico: any[] = [];
  ods: any[] = [];
  midia: any[] = [];
  fonteFinanciamento: any;
  fotos: any[] = [];

  windowScrolled!: boolean;
  activeSection = 1;

  imagemPerfilMenu: any;
  listaProjetos: any[] = [];

  constructor(
    private empreendedorPublicoService: EmpreendedorPublicoService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private odsService: ODSService,
    private chamadaPublicoService: ChamadaPublicoService,
    private fotosUsuarioService: FotosUsuarioService
  ) {
    this.isLoggedIn$ = this.authenticationService.isLoggedIn;
  }

  ngOnInit() {
    this.acao = this.activatedRoute.snapshot.params["acao"];
    this.idAcao = this.activatedRoute.snapshot.params["id"];

    this.userlogged();
    this.buscarDadosLandingPage();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToUrl(url: any): void {
    window.open(`http://${url}`, '_blank')
  }

  buscarDadosLandingPage(): void {
    let idEnvio = null;

    if (this.acao && this.idAcao) {
      idEnvio = this.idAcao;
    }

    if (this.dadosLandingPage) {
      this.empreendedor = [];
      idEnvio = this.dadosLandingPage.id;
    } else if (this.idChamada) {
      idEnvio = this.idChamada;
    }

    if (idEnvio) {
      this.empreendedor = [];
      this.empreendedorPublicoService.buscarPorId(idEnvio).subscribe(data => {
        if(data?.id){
          this.fotosUsuarioService.buscarFotoPerfil(data?.id).subscribe({
            next: (data) => {
              if (data.base64Content != null) {
                this.imagemPerfilConta = data;
                if (this.imagemPerfilConta?.base64Content && this.imagemPerfilConta?.extencao) {
                  this.imagemPerfilConta = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfilConta?.extencao};base64,${this.imagemPerfilConta?.base64Content}`);
                }
              }
            },
            error: (erro) => console.log(erro)
          })
        }

        this.ajustarDadosLandingPage(data);
      });
    }

    if (!idEnvio && !this.dadosLandingPage) {
      this.document.location.href = `/`;
    }
  }

  ajustarDadosLandingPage(dadosLandingPage: any): void {
// console.log('Dados LandingPage', dadosLandingPage)

    this.empreendedor = dadosLandingPage;
    this.areaInteresse = this.empreendedor?.areaInteresse.filter((x: { select: boolean; }) => x.select == true);
    this.ods = this.empreendedor?.ods.filter((x: { select: boolean; }) => x.select == true);
    this.fotos = this.empreendedor?.fotos;
    this.midia = this.empreendedor?.midia;
    // this.odsService.popularImagens(this.ods);

    this.ordenarFn(this.ods);
    this.ordenarFn(this.areaInteresse);
    this.sortOutrosFn(this.areaInteresse);
    this.fotosEmpreendedor();
  }

  fotosEmpreendedor() {
    let fotoBanner = this.fotos.find(x => x.tpFoto?.nomeTipoFoto == TipoFotoEnum.Capa && x.base64Content);

    if (fotoBanner?.base64Content != null) {
      this.imagemCapaBanner = fotoBanner;

      if (this.imagemCapaBanner?.base64Content && this.imagemCapaBanner?.extencao) {
        this.imagemCapaBanner = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemCapaBanner?.extencao};base64,${this.imagemCapaBanner?.base64Content}`);
      }
    }

    let fotoPerfil = this.fotos.find(x => x.tpFoto?.nomeTipoFoto == TipoFotoEnum.Perfil && x.base64Content);

    if (fotoPerfil?.base64Content != null) {
      this.imagemPerfilMenu = fotoPerfil;

      if (this.imagemPerfilMenu?.base64Content && this.imagemPerfilMenu?.extencao) {
        this.imagemPerfilMenu = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfilMenu?.extencao};base64,${this.imagemPerfilMenu?.base64Content}`);
      }
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset > 300) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  // editar quando tiver a chamada correta
  chamadasEmDestaque() {
    this.chamadaPublicoService.listarEmDestaque().subscribe((data: any) => {
      //this.listaProjetos = data.content;
    });
  }

  fotosChamada(item: any): any {
    if (item) {
      if (item.base64Content && item.base64info) {
        let img = item.base64info + item.base64Content;
        if (img) {
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }
      }
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  imagensDominios(item: any) {
    if (item?.base64Content && item?.extencao)
      return this.sanitizer.bypassSecurityTrustUrl(`data:image/${item?.extencao};base64,${item?.base64Content}`);
    else
      return undefined;
  }

  visualizarLandingPage(idProjeto: any) {
    window.open(`${window.location.origin}/#/landingpage/projeto/visualizar/${idProjeto}`, '_blank')
  }

  ordenarFn(item: any) {
    let semOrdenacao = item[0].ordenacao;
    if (semOrdenacao == null) {
      if (item == this.ods) {
        this.odsService.popularOrdenacao(item);
        this.sortOrdenacaoFn(item)
      }
      else if (item == this.areaInteresse) {
        this.sortNameFn(item)
      }
    }
    else if (item == this.ods)
      this.sortOrdenacaoFn(item)
    else
      this.sortNameFn(item)
  }

  sortOrdenacaoFn(item: any) {
    item.sort((x: any, y: any) => {
      let a = x.ordenacao,
        b = y.ordenacao;
      return a == b ? 0 : a > b ? 1 : -1;
    });
  }

  sortNameFn(item: any) {
    if (item == this.areaInteresse) {
      item.sort((x: any, y: any) => {
        let a = x.nomeAreaInteresse.toUpperCase(),
          b = y.nomeAreaInteresse.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      });
    }
  }

  sortOutrosFn(item: any) {
    if (item == this.areaInteresse) {
      let nomeOutras = item.map((x: any) => x.nomeAreaInteresse.toUpperCase() == 'OUTRAS');
      if (nomeOutras.length) {
        const itemFiltrado = item.filter((x: any) => x.nomeAreaInteresse.toUpperCase() == 'OUTRAS');
        this.areaInteresse = item.filter((x: any) => x.nomeAreaInteresse.toUpperCase() != "OUTRAS");

        if (itemFiltrado[0])
          this.areaInteresse.push(itemFiltrado[0]);
      }
    }
  }

  openModalExportPdf(): void {
    let modalExportPdf = this.modalService.open(ModalExportPdfComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'xl'
    });
    modalExportPdf.componentInstance.empreendedor = this.empreendedor;
    modalExportPdf.componentInstance.areaInteresse = this.areaInteresse;
    modalExportPdf.componentInstance.ods = this.ods;
    modalExportPdf.componentInstance.midia = this.midia;
    modalExportPdf.componentInstance.imagemPerfilMenu = this.imagemPerfilMenu;
    modalExportPdf.componentInstance.imagemCapaBanner = this.imagemCapaBanner;
  }

  public selectLanguage(event: any) {
    const linguagemSelecionada = event.value; // Captura a linguagem selecionada
    console.log('Linguagem selecionada:', linguagemSelecionada);
    this.translateService.use(event.value)
    this.linguas = (event.id)
// console.log(this.linguas)
  }

  get translator() {
    return this.translateService.get('KEY_TO_TRANSLATE').subscribe(x => this.translateService = x);
  }

  userlogged(): void {
    this.papelAtivo = this.authenticationService.currentUserValue?.papelAtivo;
  }
}
