import { ModalLoginComponent } from '@modules/login/components/modal-login/modal-login.component';
import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { NotificationService } from '@core/services/notification.service';
import { first, switchMap } from 'rxjs/operators';
import { RouteAction } from '@core/action/action';
import { AreaInteresseService } from '@core/services/area-interesse.service';
import { ODSService } from '@core/services/ods.service';
import { PublicoAlvoService } from '@core/services/publico-alvo.service';
import { Subscription, interval } from 'rxjs';
import * as dayjs from 'dayjs';
import { DOCUMENT } from '@angular/common';
import { TipoFotoEnum } from '@core/models/enum/tipo-foto';
import { DomSanitizer } from '@angular/platform-browser';
import { ArquivoService } from '@core/services/arquivo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { ModalInscreverComponent } from '@modules/chamadas/pages/public/components/modal-inscrever/modal-inscrever.component';
import { PatrocinadorPublicoService } from '@core/services/patrocinador-publico';
import { TranslateService } from '@ngx-translate/core';
import { FotosUsuarioService } from '@core/services/fotos-usuario.service';

@Component({
  selector: 'app-landingpage-patrocinador',
  templateUrl: './landingpage-patrocinador.component.html',
  styleUrls: ['./landingpage-patrocinador.component.scss']
})
export class LandingpagePatrocinadorComponent implements OnInit, OnDestroy {

  cities = [
    {
      id: 0,
      value: "pt-BR",
      avatar: 'https://static.todamateria.com.br/upload/ba/nd/bandeira-do-brasil-og.jpg',
      lingua: 'PT'
    },
    {
      id: 1,
      value: "en-US",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/2560px-Flag_of_the_United_States.svg.png',
      lingua: 'EN'
    },
    {
      id: 2,
      value: "es-ES",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png',
      lingua: 'ES'
    }
  ];
  selectedCity = this.cities[0];
  linguas = 0
  namePage = "Landing Page Patrocinador";
  paginaAtual: string | undefined;

  subscription!: Subscription;
  dateNow = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  timeDifference!: number;
  secondsToDday!: number;
  minutesToDday!: number;
  hoursToDday!: number;
  daysToDday!: number;

  usuarioForm!: FormGroup;
  idAcao!: any;
  acao!: any;
  routeAcao!: RouteAction;
  criar!: boolean;
  editar!: boolean;
  visualizar!: boolean;

  usuarioLogado: any;
  idContaAtiva: any;
  papelAtivo: any;
  imagemPerfilConta!: string | any;
  papelContaEnum = PapelContaEnum;
  usuarioLogadoContas: any[] = [];
  idUsuarioLogado: any;
  message!: string;
  isLoading: boolean = false;
  success: boolean = false;
  exibirErroVista: boolean = false;
  objEnvio: any;
  public isMenuCollapsed = true;

  patrocinador: any;
  imagemPerfil: string|any;
  imagemCapaBanner: string|any;
  dataLimiteInscricao: any;
  exibirContador: boolean = false;

  areaInteresse: any;
  publico: any;
  ods: any;
  midia: any;
  fonteFinanciamento: any;
  fotos: any[] = [];
  listaEditais: any[] = [];

  windowScrolled!: boolean;
  activeSection = 1;

  imagemPerfilMenu: any;

  constructor(
    private patrocinadorPublicoService: PatrocinadorPublicoService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private areaInteresseService: AreaInteresseService,
    private publicoAlvoService: PublicoAlvoService,
    private odsService: ODSService,
    private fotosUsuarioService: FotosUsuarioService
  ) { }

  ngOnInit() {
    this.userlogged();
    this.acao = this.activatedRoute.snapshot.paramMap.get("acao");
    this.idAcao = this.activatedRoute.snapshot.paramMap.get("id");
    if (this.idAcao)
      this.buscarDadosEmpreendedor(this.idAcao);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToUrl(url: any): void {
    this.document.location.href = `http://${url}`;
  }

  buscarDadosEmpreendedor(idEmpreendedor: number) {
    this.patrocinadorPublicoService.buscarPorId(idEmpreendedor)
      .pipe(first())
      .subscribe((data: any) => {

        if(data?.id){
          this.fotosUsuarioService.buscarFotoPerfil(data?.id).subscribe({
            next: (data) => {
              if (data.base64Content != null) {
                this.imagemPerfilConta = data;
                if (this.imagemPerfilConta?.base64Content && this.imagemPerfilConta?.extencao) {
                  this.imagemPerfilConta = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfilConta?.extencao};base64,${this.imagemPerfilConta?.base64Content}`);
                }
              }
            },
            error: (erro) => console.log(erro)
          })
        }

        this.patrocinador = data;
        this.midia = this.patrocinador?.midia;
        this.fotos = this.patrocinador?.fotos;
        this.fonteFinanciamento = this.patrocinador?.fonteFinanciamento;

        this.fotosEmpreendedor();
      });
  }

  fotosEmpreendedor(){
    let fotoBanner = this.fotos.find(x => x.tpFoto?.nomeTipoFoto == TipoFotoEnum.Capa && x.base64Content);

    if(fotoBanner?.base64Content != null){
      this.imagemCapaBanner = fotoBanner;

      if (this.imagemCapaBanner?.base64Content && this.imagemCapaBanner?.extencao) {
        this.imagemCapaBanner = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemCapaBanner?.extencao};base64,${this.imagemCapaBanner?.base64Content }`);
      }
    }

    let fotoPerfil = this.fotos.find(x => x.tpFoto?.nomeTipoFoto == TipoFotoEnum.Perfil && x.base64Content);

    if(fotoPerfil?.base64Content != null){
      this.imagemPerfilMenu = fotoPerfil;

      if (this.imagemPerfilMenu?.base64Content && this.imagemPerfilMenu?.extencao) {
        this.imagemPerfilMenu = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfilMenu?.extencao};base64,${this.imagemPerfilMenu?.base64Content }`);
      }
    }
    //this.imagemCapaBanner = this.imagemCapaBanner?.changingThisBreaksApplicationSecurity.find((x: { unsafe: any; }) => x.unsafe);

  }

  inscrever(){
    if(this.papelAtivo != this.papelContaEnum.Empreendedor){
      this.openModal();
    } else if(this.papelAtivo == this.papelContaEnum.Empreendedor){
      this.router.navigate([`/chamadas/inscrever-se/editar/${this.idAcao}`]);
    }
  }


  openModal() {
    let modalInscricao = this.modalService.open(ModalInscreverComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
    modalInscricao.componentInstance.idEmpreendedor = this.idAcao;
    modalInscricao.componentInstance.papelAtivo = this.papelAtivo;
  }


  modalLogin() {
    let modalLogin = this.modalService.open(ModalLoginComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset > 300) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  fotosChamada(item: any): any {
    if(item){
      if(item.base64Content && item.base64info){
        let img = item.base64info + item.base64Content;
        if(img){
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }
      }
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  visualizarLandingPage(idChamada: any){
    window.open(`${window.location.origin}/#/landingpage/chamada/visualizar/${idChamada}`, '_blank')
  }

  public selectLanguage(event: any) {
    const linguagemSelecionada = event.value; // Captura a linguagem selecionada
    console.log('Linguagem selecionada:', linguagemSelecionada);
    this.translateService.use(event.value)
    this.linguas = (event.id)
// console.log(this.linguas)
  }

  userlogged(): void {
    this.papelAtivo = this.authenticationService.currentUserValue?.papelAtivo;
  }
}
